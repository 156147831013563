import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Kneeling D Ball Chest Pass + Clapping Pushup 4×4`}</p>
    <p>{`Banded Straight Arm Pulldown 4×6`}</p>
    <p>{`Shoulder Press 4×4\\@80%1RM`}</p>
    <p><em parentName="p">{`*`}{`do these in a circuit`}</em></p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`10-Bench Press (135/85) (RX+ 185/115)`}</p>
    <p>{`10-Devil’s Press (35/20) (RX+ 50/35)`}</p>
    <p>{`15-Box Jump Overs (24/20)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Grab 2 friends and register for the Granite Games Spring Throwdown
on March 14th!  February 16th is the last day to register and get your
free Granite Games t shirt.  The 16th is also the last day to get your
volunteer t shirt so if you can’t compete please consider helping.  Sign
up to compete or volunteer
at: `}<a parentName="em" {...{
            "href": "https://competitioncorner.net/events/3164"
          }}>{`https://competitioncorner.net/events/3164`}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      